<template>
  <div>
    <v-icon>
      {{ icons.mdiCheckCircle }}
    </v-icon>
    <p>เสร็จสิ้น</p>
  </div>
</template>

<script>
import { mdiCheckCircle } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiCheckCircle,
      },
    }
  },
}
</script>

<style></style>
